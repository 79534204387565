import React from 'react';
import { connect } from 'react-redux';
import Atm from '../../components/Atm/Atm';
import Filters from '../../components/Filters/Filters';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';
import SearchBox from '../../components/Input/SearchBox';
import SearchResultList, {
    SearchResultListElem,
} from '../../components/SearchResult/SearchResult';
import { PanelContent } from '../../shared/constants/enum';
import { BaseBox } from '../../shared/constants/theme';
import { IStore } from '../../store/reducers/store';
import { AtmElement, MapMode } from '../../types/models';
import * as SC from './MapAtmSearch.styled';
import NavigateToMapView from './NavigateToMapView';

type LeftPanelProps = {
    contentType: PanelContent;
    mapMode: MapMode;
    hasLoaded?: boolean;
    currentAtms?: AtmElement[];
    onMoreClick?: () => void;
    showLoadMore?: boolean;
};
const LeftPanel = (props: LeftPanelProps) => {
    const { contentType, mapMode } = props;

    return (
        <SC.SearchBar data-testid="desktop-left-panel">
            {contentType === PanelContent.SEARCH ? (
                <SC.SearchBarContainer>
                    <SearchResultListElem>
                        {(props.hasLoaded || mapMode === MapMode.NoMaps) && (
                            <SearchBox />
                        )}
                        {mapMode !== MapMode.NoMaps ? (
                            props.hasLoaded && (
                                <BaseBox as="div" top={2} bottom={2}>
                                    <Filters />
                                </BaseBox>
                            )
                        ) : (
                            <NavigateToMapView />
                        )}
                        <SearchResultList
                            showLoadMore={props.showLoadMore}
                            currentAtms={props.currentAtms}
                            onMoreClick={props.onMoreClick}
                        />
                    </SearchResultListElem>
                </SC.SearchBarContainer>
            ) : (
                <SC.Views>
                    {contentType === PanelContent.FEEDBACK && (
                        <FeedbackForm data-testid="atm-complaint-link" />
                    )}
                    {contentType === PanelContent.DETAILS && <Atm />}
                </SC.Views>
            )}
        </SC.SearchBar>
    );
};

const mapStoreToProps = (state: IStore) => {
    return {
        contentType: state.common.panelContent,
        mapMode: state.common.mapMode,
        hasLoaded: !!state.atms.currentAtms,
    };
};

export default connect(mapStoreToProps)(LeftPanel);
