import React, { useMemo } from 'react';
import MobileSearchResultPopup from '../../components/SearchResult/MobileSearchResultPopup';
import SearchResultList from '../../components/SearchResult/SearchResult';

import MobileSearchBox from '../../components/SearchResult/MobileSearchBox';
import { MapAtmSearchProps } from './MapAtmSearch.d';
import * as SC from './MapAtmSearch.styled';

import { useTranslation } from 'react-i18next';

import { connect, useDispatch } from 'react-redux';
import { setMobileView, setPanelContent } from '../../store/actions/common';

import Atm from '../../components/Atm/Atm';
import Button from '../../components/Button';
import FeedbackForm from '../../components/FeedbackForm/FeedbackForm';
import SearchBox from '../../components/Input/SearchBox';
import Spinner from '../../components/Spinner/Spinner';
import { MobileView, PanelContent } from '../../shared/constants/enum';
import { useMobileDetection } from '../../shared/hooks';
import FullscreenIcon from '../../shared/img/fullscreen.svg';
import ListIcon from '../../shared/img/list.svg';
import { IStore } from '../../store/reducers/store';
import LeftPanel from './LeftPanel';

import { SizeMe } from 'react-sizeme';
import { AtmElement, MarkerHashMap } from '../../types/models';
import { useMarkerVariant } from './hooks/useMarkerVariant';
import { useRecommendationMarkers } from './hooks/useRecommendationMarkers';
import { useAutoMoveTo } from './hooks/useAutoMoveTo';
import { useHomeAddress } from './hooks/useHomeAddress';
import { useHomeMarker } from './hooks/useHomeMarker';
import { useMarkerClick } from './hooks/useMarkerClick';
import { useResultMarkers } from './hooks/useResultMarkers';
import { useSlicedAtms } from './hooks/useSlicedAtms';
import { useYellowMap } from './hooks/useYellowMap';
import Filters from '../../components/Filters/Filters';
import { useFullscreen } from './hooks/useFullscreen';
import { DEFAULT_FILTER_DEPOSIT } from '../../shared/constants/default';
import { atmUtils } from '../../helpers/atm-utils';

const MapAtmSearch = ({
    userLocation,
    atms,
    isFetchingAtm,
    currentLat,
    currentLong,
    filter,
    mobileViewType,
    panelContent,
    detailedAtm,
}: MapAtmSearchProps) => {
    const userLat = userLocation?.coords?.latitude;
    const userLng = userLocation?.coords?.longitude;

    const containerRef = React.useRef<HTMLDivElement | null>(null);

    const { t } = useTranslation();
    const dispatch = useDispatch();
    const { isMobile } = useMobileDetection();

    // TODO START temporary solution please remove
    const filteredAtms = useMemo(() => {
        let filtered: Record<string, AtmElement> | undefined;

        if (atms) {
            filtered = {};

            for (const atmKey of Object.keys(atms)) {
                const atm = atms[atmKey];

                const isFilterDepositeActive =
                    filter?.indexOf(DEFAULT_FILTER_DEPOSIT) !== -1;

                if (isFilterDepositeActive) {
                    const isDepositAvailable =
                        atmUtils.isDeposit(atm) ||
                        atmUtils.isCashbackPartner(atm);

                    if (isDepositAvailable) {
                        filtered[atmKey] = atm;
                    }
                } else {
                    filtered[atmKey] = atm;
                }
            }
        }

        return filtered;
    }, [atms, filter]);
    // TODO END temporary solution please remove

    const { currentAtms, hasMoreAtms, showMoreAtms, totalCount } =
        useSlicedAtms({ atms: filteredAtms });

    const markersById = React.useRef<MarkerHashMap>({});

    const [mapElement, setMapElement] = React.useState<HTMLDivElement | null>(
        null
    );
    const handleMapElement = React.useCallback(
        (node) => setMapElement(node),
        []
    );
    const { ymReady } = useYellowMap(mapElement);

    const { onMarkerClick } = useMarkerClick({ atms: filteredAtms });

    useHomeAddress({
        ymReady,
        userLat,
        userLng,
    });

    useHomeMarker({
        ymReady,
        userLat,
        userLng,
    });

    useResultMarkers({
        ymReady,
        atms: filteredAtms,
        markersById,
        onMarkerClick,
    });

    useMarkerVariant({
        ymReady,
        atm: detailedAtm,
        markersById,
        variant: 'active',
    });

    useRecommendationMarkers({
        ymReady,
        markersById,
        currentAtms,
    });

    useAutoMoveTo({
        ymReady,
        lat: currentLat,
        lng: currentLong,
    });

    const { toggleFullscreen } = useFullscreen(containerRef);
    const handleShowList = React.useCallback(() => {
        dispatch(setPanelContent(PanelContent.SEARCH));
        dispatch(setMobileView(MobileView.CONTENT));
    }, [dispatch]);

    const isLoading = isFetchingAtm || (isMobile && !filteredAtms);
    const isPreviewMapVisible =
        mobileViewType === MobileView.CONTENT &&
        panelContent === PanelContent.DETAILS;

    return (
        <SC.MainContainer ref={containerRef} data-testid="map-atm-search">
            <SC.MobileSearchBarWrapper>
                {isMobile && panelContent === PanelContent.SEARCH && (
                    <MobileSearchResultPopup
                        initiallyOpen={true}
                        data-testid="mobile-search-result"
                    >
                        <SearchResultList
                            currentAtms={currentAtms}
                            showLoadMore={hasMoreAtms}
                            onMoreClick={showMoreAtms}
                        />
                    </MobileSearchResultPopup>
                )}

                <MobileSearchBox>
                    <SearchBox />
                    <Filters />
                </MobileSearchBox>

                <SC.SearchResultButtonWrapper
                    hideButton={
                        mobileViewType === MobileView.CONTENT ||
                        totalCount === 0
                    }
                >
                    <Button onClick={handleShowList}>
                        <img src={ListIcon} alt="icon" />
                        {t('showSearchResultsButton')}
                        {totalCount ? ` (${totalCount})` : ''}
                    </Button>
                </SC.SearchResultButtonWrapper>

                {isPreviewMapVisible && <Atm showPreviewMap />}

                {panelContent === PanelContent.FEEDBACK && (
                    <FeedbackForm data-testid="atm-complaint-link" />
                )}
            </SC.MobileSearchBarWrapper>

            {!isMobile && (
                <LeftPanel
                    currentAtms={currentAtms}
                    showLoadMore={hasMoreAtms}
                    onMoreClick={showMoreAtms}
                />
            )}

            <SizeMe noPlaceholder monitorHeight>
                {({ size }) => {
                    return (
                        <SC.MapContainer id="map-wrapper">
                            <div
                                style={{
                                    maxWidth: size.width!,
                                    height: size.height!,
                                }}
                            >
                                {size.width && size.height && (
                                    <div ref={handleMapElement} id="map" />
                                )}
                            </div>
                        </SC.MapContainer>
                    );
                }}
            </SizeMe>

            <SC.FullscreenButton
                onClick={toggleFullscreen}
                aria-label="Vollbildansicht ein/aus"
                title="Vollbildansicht ein/aus"
            >
                <img src={FullscreenIcon} alt="Icon" />
            </SC.FullscreenButton>

            {isLoading && <Spinner centered fixed overlay />}
        </SC.MainContainer>
    );
};

const mapStoreToProps = (store: IStore) => {
    return {
        atms: store.atms.currentAtms,
        detailedAtm: store.detailedAtm,
        currentLat: store.atms.currentLat,
        currentLong: store.atms.currentLong,
        currentPage: store.atms.currentPage,
        filter: store.filters.activeFilter,
        panelContent: store.common.panelContent,
        isFetchingAtm: store.atms.isFetchingAtm,
        mobileViewType: store.common.mobileViewType,
        userLocation: store.location.userLocation,
        userPermissionError: store.location.userPermissionError,
    };
};

export default connect(mapStoreToProps)(MapAtmSearch);
