import React from 'react';
import styled from 'styled-components';
import { Device, utp } from '../../helpers/utilities';
import { connect } from 'react-redux';
import { IStore } from '../../store/reducers/store';
import { MobileView } from '../../shared/constants/enum';

const MobileSearchBoxWrapper = styled.div<{ hide: boolean }>`
    position: relative;
    z-index: ${1 << 12};
    padding: ${utp(Device.Smartphone, 4)} 5%;
    display: ${(props) => (props.hide ? 'none' : 'block')};
    background: white;
    > * + * {
        padding-top: ${utp(Device.Smartphone, 4)};
    }
`;

type MobileSearchBoxProps = {
    children: any;
    mobileViewType?: MobileView;
};

const MobileSearchBox = ({
    children,
    mobileViewType,
}: MobileSearchBoxProps) => {
    return (
        <MobileSearchBoxWrapper hide={mobileViewType === MobileView.CONTENT}>
            {children}
        </MobileSearchBoxWrapper>
    );
};

const mapStoreToProps = (store: IStore) => {
    return {
        mobileViewType: store.common.mobileViewType,
    };
};

export default connect(mapStoreToProps, null)(MobileSearchBox);
